import {
    Vector3,
    Euler,
    EventDispatcher,
  } from 'three';

  export default class PointerLockControlsTouch extends EventDispatcher {

    _euler;

	_vector = new Vector3();

	_changeEvent = {
		type: 'change'
	};

	_PI_2 = Math.PI / 2;

	domElement = null;

	minPolarAngle = 0;

	maxPolarAngle = Math.PI;

	pointerSpeed = 1.0;

	scope;

	camera;

	previousTouch;

		constructor( cameraInput, domElementInput ) {

			super();

			this._euler = new Euler( 0, 0, 0, 'YXZ' );

			console.warn(this._euler);

			if ( domElementInput === undefined ) {

				console.warn( 'THREE.PointerLockControls: The second parameter "domElement" is now mandatory.' );
				domElementInput = document.body;

			}

			this.domElement = domElementInput;
			this.camera = cameraInput;
			// this.isLocked = false;
            // Set to constrain the pitch of the camera
			// Range is 0 to Math.PI radians

			this.minPolarAngle = 0; // radians

			this.maxPolarAngle = Math.PI; // radians

			this.pointerSpeed = 1.0;

			this.scope = this;

			this.connect();
		}

		onMouseMoveCustom( event ) {

			const touch = event.touches[0];

			if (this.previousTouch) {
			// be aware that these only store the movement of the first touch in the touches array
				event.movementX = touch.pageX - this.previousTouch.pageX;
				event.movementY = touch.pageY - this.previousTouch.pageY;
			};

			this.previousTouch = touch;

			// if ( scope.isLocked === false ) return;
			const movementX = event.movementX || 0;
			const movementY = event.movementY || 0;

			this._euler.setFromQuaternion( this.camera.quaternion );

			this._euler.y += movementX * 0.002 * this.scope.pointerSpeed;
			this._euler.x += movementY * 0.002 * this.scope.pointerSpeed;
			// this._euler.x = Math.max( this._PI_2 - this.scope.maxPolarAngle, Math.min( this._PI_2 - this.scope.minPolarAngle, this._euler.x ) );
			this.camera.quaternion.setFromEuler( this._euler );
			this.scope.dispatchEvent( this._changeEvent );

		}

		onMouseStartCustom(event){
			this.previousTouch = undefined;

		}


		connect() {

			this.scope.domElement.ownerDocument.addEventListener("touchstart", (event) => {
				this.onMouseStartCustom(event);
			});

			this.scope.domElement.ownerDocument.addEventListener("touchmove", (event) => {
				this.onMouseMoveCustom(event);
			});

			// scope.domElement.ownerDocument.addEventListener( 'pointerlockchange', onPointerlockChange );
			// scope.domElement.ownerDocument.addEventListener( 'pointerlockerror', onPointerlockError );

		};

		disconnect() {

			this.scope.domElement.ownerDocument.removeEventListener( 'touchmove', (event) => { this.onMouseMoveCustom(event) } );
			// scope.domElement.ownerDocument.removeEventListener( 'pointerlockchange', onPointerlockChange );
			// scope.domElement.ownerDocument.removeEventListener( 'pointerlockerror', onPointerlockError );

		};

		dispose() {

			this.disconnect();

		};

		getObject() {

			// retaining this method for backward compatibility
			return this.camera;

		};

		getDirection() {

			const direction = new Vector3( 0, 0, - 1 );
			return function ( v ) {

				return v.copy( direction ).applyQuaternion( this.camera.quaternion );

			};
		}

		moveForward( distance ) {

			// move forward parallel to the xz-plane
			// assumes camera.up is y-up
			this._vector.setFromMatrixColumn( this.camera.matrix, 0 );

			this._vector.crossVectors( this.camera.up, this._vector );

			this.camera.position.addScaledVector( this._vector, distance );

		};

		moveRight( distance ) {

			this._vector.setFromMatrixColumn( this.camera.matrix, 0 );

			this.camera.position.addScaledVector( this._vector, distance );

		};



	}

